<div #wrapper></div>
<button *ngIf="json != null" class="expand-transition menu-trigger-button" [ngClass]="{ 'menu-hidden': showEdit === false }" (click)="menuChange.emit()">
    <span *ngIf="showEdit === false" class="material-icons notranslate">
        menu_open
    </span>
    <span *ngIf="showEdit === true" class="material-icons notranslate">
        close
    </span>
</button>
<button *ngIf="json" class="expand-transition refresh-trigger-button" (click)="distribute()">
  <span class="material-icons notranslate">
    refresh
  </span>
</button>
<div *ngIf="busy === true" class="busy-container">
    <div class="loader"></div>
</div>
