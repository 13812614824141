<!-- <div class="url-container">
    <label class="file-loader-button" for="file-loader">Load VTB File</label>
    <input type="file" id="file-loader" class="file-loader" (change)="gatherFromFile($event)" #fileImport>
    <button class="download-button">Download</button>
    <div class="url-reader">
        <input type="text" [(ngModel)]="buildUrl">
        <button (click)="fetchData()">Gather data</button>
    </div>
</div> -->
<div *ngIf="editObj && editObj.type === 'static-image'" class="text-editor">
    <app-static-image-edit [isChanged]="isChanged" [content]="editObj.input" [zraId]="editObj.zraId" [type]="editObj.staticType" (save)="saveData($event, 'static-image')" (revert)="revertData()"></app-static-image-edit>
</div>
<div *ngIf="editObj && editObj.type === 'text'" class="text-editor">
    <app-text-editor [id]="editObj.vtbObjectId" [field]="editObj.vtbField" [zraId]="editObj.zraId" [history]="editObj.history" [isChanged]="isChanged" [content]="editObj.input" (save)="saveData($event, 'text')" (revert)="revertData()"></app-text-editor>
</div>
<div *ngIf="editObj && editObj.type === 'media'" class="image-editor">
    <app-image-editor [id]="editObj.vtbObjectId" [isChanged]="isChanged" [content]="editObj.data" [max]="editObj.maxImg" (save)="saveData($event, 'media')" (revert)="revertData()"></app-image-editor>
</div>
<div *ngIf="editObj && editObj.type === 'plain-price'" class="price-editor">
    <app-plain-price-edit [content]="editObj.input" [originalPrice]="editObj.originalPrice" [zraId]="editObj.zraId" [maxDec]="editObj.maxDec" [maxInc]="editObj.maxInc" (save)="saveData($event, 'price')" (revert)="revertData()"></app-plain-price-edit>
</div>
<div *ngIf="editObj && editObj.type === 'advanced-price'" class="price-editor">
    <app-advanced-price-edit [id]="editObj.vtbObjectId" [content]="editObj.data" (save)="saveData($event, 'price')"></app-advanced-price-edit>
</div>