import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { SavedTemplatesDialogComponent } from '../saved-templates-dialog/saved-templates-dialog.component';
import { PublishInfoDialogComponent} from '../publish-info-dialog/publish-info-dialog.component';
import { ImportDialogComponent } from '../import-dialog/import-dialog.component';
import { ApiService } from 'src/app/services/api.service';
declare let window: any;

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {
  @Input() json: any;
  @Output() editor = new EventEmitter();
  @Output() busyTrigger = new EventEmitter();

  publishedTemplates = [];
  savedTemplates = [];

  niceUrl = '';

  buildUrl = '';
  // buildUrl = 'https://opreismet.vanverre.nl/932/1d871fb1-9d05-43b1-8731-f076cfb4406b/zra.json';

  constructor(
    public dialog: MatDialog,
    public dataService: DataService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private helperService: HelperService,
    private http: HttpClient,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    const authNiceUrl = this.authService.niceUrl();
    if (authNiceUrl != null && authNiceUrl !== this.niceUrl) {
      this.niceUrl = authNiceUrl;
    }
  }

  fetchData() {
    this.savedTemplates = [];
    this.publishedTemplates = [];

    this.editor.emit({ type: 'fetch', url: this.buildUrl });

    this.api.getSavedTemplates(this.buildUrl).then((result:any) => {
      result.templates.forEach(template => {
        const resultDate = new Date(Number(template.time));

        this.savedTemplates.push({
          name: `Template ${this.savedTemplates.length + 1}`,
          type: 'pdf changes',
          date: resultDate
        });
      });

      this.savedTemplates = this.helperService.sortTemplatesByDate(this.savedTemplates);

      if (this.savedTemplates.length) {
        const dialogRef = this.dialog.open(SavedTemplatesDialogComponent, {
          data: { savedTemplates: this.savedTemplates }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.name) {
            this.openSavedTemplate(result);
          }
        });
      }
    });

    this.http.get(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/build/${this.api.buildCode(this.buildUrl)}`, {
      headers: {
        'Agent-Token': this.authService.getToken()
      }
    }).subscribe((result: any) => {
      for (const template of result.templates) {
        template.date = new Date(Number(template.time));
      }
      result.templates = this.helperService.reverseSortTemplatesByDate(result.templates);

      result.templates.forEach(template => {
        this.publishedTemplates.push({
          name: `Template ${this.publishedTemplates.length + 1}`,
          type: 'pdf',
          date: template.date,
          folderName: template.resultDate,
          link: `${this.niceUrl}${template.folder}output.pdf`
        });
      });

      this.publishedTemplates = this.helperService.sortTemplatesByDate(this.publishedTemplates);
    });
  }

  // gatherFromFile($event) { //todo: fix this
  //   const reader = new FileReader();
  //   reader.readAsText($event.target.files[0], 'UTF-8');
  //   reader.onload = (event) => {
  //     if (event.target && event.target.result) {
  //       const data = JSON.parse(event.target.result as string);
  //       this.editor.emit({ type: 'fetch', url: data.url, json: data.json, jsonChanges: data.jsonChanges });
  //     }
  //   };
  // }

  openPublishedTemplate(template) {
    if (template.status !== 'pending') {
      window.open(this.formatLink(template.link));
    }
  }

  importTemplate()
  {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      data: { itinerary: this.dataService.getJSON() }
    });
    dialogRef.afterClosed().subscribe(result => {
      // if (result.url) {
      //   this.openSavedTemplate(result);
      // }
    });
  }

  logout()
  {
    localStorage.setItem('id_token', '');
    window.location.reload();
  }

  openSavedTemplate(template) {
    if (template.status !== 'pending') {
      this.busyTrigger.emit(true);

      this.http.get(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/proposal/${this.api.buildCode(this.buildUrl)}/${template.date.getTime()}`, {
        headers: {
          'Agent-Token': this.authService.getToken()
        }
      }).subscribe((result: any) => {
        window.loadedSavedTemplate = true;
        this.editor.emit({ type: 'openSaved', data: { itinerary: JSON.parse(result.itinerary) } });
      });

      this.notificationsService.warn('Opening saved template', '', {
        timeOut: 10000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }
  }

  saveTemplate() {
    this.notificationsService.warn('Saving...', '', {
      timeOut: 10000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });

    return new Promise((resolve: any) => {
      this.http.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/proposal/${this.api.buildCode(this.buildUrl)}`,
        JSON.stringify(this.dataService.getJSON()),
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Agent-Token': this.authService.getToken()
          }
        }
      ).subscribe((result: any) => {
        const resultDate = new Date(Number(result.time));

        this.savedTemplates.push({
          name: `Template ${this.savedTemplates.length + 1}`,
          type: 'pdf changes',
          date: resultDate
        });

        this.savedTemplates = this.helperService.sortTemplatesByDate(this.savedTemplates);
        this.editor.emit({type: 'isSaved'});
        resolve();
      });
    });
  }

  publishTemplate() {
    this.notificationsService.warn('Building...', 'Publishing currently opened and edited template, this will take a few minutes', {
      timeOut: 60000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });

    const dialogRef = this.dialog.open(PublishInfoDialogComponent, {
      width: '400px',
    });

    this.http.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/build/${this.api.buildCode(this.buildUrl)}`,
      JSON.stringify({
        itinerary: this.dataService.getJSON(),
        zraJSON: this.buildUrl
      }),
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Agent-Token': this.authService.getToken()
        }
      }
    ).subscribe((result: any) => {
      if (result.success) {
        let template = {
          name: `Template ${this.publishedTemplates.length + 1}`,
          type: 'pdf',
          date: result.time,
          folderName: result.folderName,
          link: `${this.niceUrl}${result.folderName}/output.pdf`,
          status: 'pending'
        };



        this.publishedTemplates.push(template);
        this.checkTemplate(template);

        this.publishedTemplates = this.helperService.sortTemplatesByDate(this.publishedTemplates);
      }
    });
  }

  deleteTemplate(index) {
    const template: any = this.publishedTemplates.splice(index, 1);
    this.http.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/build/${this.api.buildCode(this.buildUrl)}`,
      JSON.stringify({
        itinerary: this.dataService.getJSON(),
        zraJSON: this.buildUrl,
        shouldDelete: true,
        folderName: template[0].folderName
      }),
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Agent-Token': this.authService.getToken()
        }
      }
    ).subscribe((result: any) => { });
  }

  republishTemplate(template) {
    if (template.status !== 'pending') {
      this.http.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/build/${this.api.buildCode(this.buildUrl)}`,
        JSON.stringify({
          itinerary: this.dataService.getJSON(),
          zraJSON: this.buildUrl,
          isOverride: true,
          folderName: template.folderName
        }),
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Agent-Token': this.authService.getToken()
          }
        }
      ).subscribe((result: any) => {
        if (result.success) {
          template.date = result.time;

          this.publishedTemplates = this.helperService.sortTemplatesByDate(this.publishedTemplates);
        }
      });

      this.notificationsService.warn('This is still work in progress', 'This feature will republish the published template with the current data. Good to use if only some small changes were made', {
        timeOut: 10000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
    }
  }

  checkTemplate(template) {
    let interval = setInterval(() => {
      this.recheckStatus(template, interval);
    }, 5000);
  }

  recheckStatus(template, interval) {
    if (this.niceUrl != null && this.niceUrl !== '') {
      this.dataService.downloadFile(this.formatLink(template.link), this.buildUrl).subscribe(response => {
        if (response) {
          template.status = 'success';
          this.notificationsService.success('PDF status has been refreshed!', '', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
          });

          clearInterval(interval);
        }
      });
    } else {
      this.notificationsService.error('No NiceURL set, plz ask TravelSpirit', '', {
        timeOut: 10000,
        showProgressBar: true,
        pauseOnHover: true,
      });
      return;
    }
  }

  formatLink(link) {
    if(link.indexOf('http') !== 0) {
      link = `https://${link}`;
    }
    return link;
  }
}
