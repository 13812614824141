import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-advanced-price-edit',
  templateUrl: './advanced-price-edit.component.html',
  styleUrls: ['./advanced-price-edit.component.scss']
})
export class AdvancedPriceEditComponent implements OnInit {
  @Input() id: string;
  @Input() content: any;
  @Input() isChanged = false;
  @Output() save = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
