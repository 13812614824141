import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-plain-price-edit',
  templateUrl: './plain-price-edit.component.html',
  styleUrls: ['./plain-price-edit.component.scss']
})
export class PlainPriceEditComponent implements OnInit, OnChanges {
  @Input() content: any;
  @Input() maxInc: number;
  @Input() maxDec: number;
  @Input() isChanged = false;
  @Input() zraId: string;
  @Output() save = new EventEmitter();
  @Output() revert = new EventEmitter();

  form: FormGroup;
  calculatedPrice = 0;
  price: number;
  margin = 0;
  @Input() originalPrice: number;
  minimalPrice = 0;
  maximumPrice = 100000;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      price: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+([.,][0-9]+)?$')])
    });
  }

  ngOnInit() {
    this.price = parseFloat(this.content);
    
    this.minimalPrice = Math.round(this.originalPrice * ((100 - this.maxDec) / 100));
    this.maximumPrice = Math.round(this.originalPrice * ((100 + this.maxInc) / 100));
    this.margin = Number(((this.price - this.originalPrice) / this.originalPrice * 100).toFixed(2));
  }

  ngOnChanges(event) {
    this.price = parseFloat(this.content);
    this.minimalPrice = Math.round(this.originalPrice * ((100 - this.maxDec) / 100));
    this.maximumPrice = Math.round(this.originalPrice * ((100 + this.maxInc) / 100));
    this.margin = Number(((this.price - this.originalPrice) / this.originalPrice * 100).toFixed(2));
  }

  recalcPrice(event) {
    if (this.price !== 0) {
      this.price = Number(Number(this.originalPrice / 100 * (100 + this.margin)).toFixed(0));
    }
  }

  recalcMargin(event) {
    if (this.price !== 0) {
      this.margin = Number(((this.price - this.originalPrice) / this.originalPrice * 100).toFixed(2));
    }
  }

  saveChanges() {
    if(this.checkPriceInput()) {
      this.save.emit({zraId: this.zraId, price: this.price});
    }
  }

  revertChanges() {
    this.revert.emit();
  }

  checkPriceInput() {
    if (this.price != null && isNaN(this.price) === false) {
      return true;
    }

    return false;
  }
}
