import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  buildCode(buildUrl) {
    const frags = buildUrl.split('/');
    return frags[frags.length - 2];
  }

  getSavedTemplates(buildUrl)
  {
    return new Promise((resolve, reject) => {
      this.http.get(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/proposal/${this.buildCode(buildUrl)}`, {
        headers: {
          'Agent-Token': this.authService.getToken()
        }
      }).subscribe((result: any) => {
        resolve(result);
      });
    })
  }

  getTemplate(buildUrl, template)
  {
    return new Promise((resolve, reject) => {
      this.http.get(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/zra/proposal/${this.buildCode(buildUrl)}/${template.date.getTime()}`, {
        headers: {
          'Agent-Token': this.authService.getToken()
        }
      }).subscribe((result: any) => {
        let itinerary = JSON.parse(result.itinerary);
        resolve(itinerary);
      });
    })
  }
}
