<ul>
    <li class="logo-container">
        <img class="vtb-agent-logo-image" src="../../assets/images/ST-logo-white.png" alt="vtb-logo">
        <!-- <p>VTB Agent Tool</p> -->
    </li>
</ul>
<ul class="right">
    <li>
        <button class="hoverable-menu-item" mat-button matTooltip="Uitloggen" (click)="logout()">
            <i class="material-icons notranslate">logout</i>
        </button>
    </li>

    <li>
        <div class="url-reader">
            <mat-form-field appearance="outline">
                <mat-label>Template url</mat-label>
                <input type="text" matInput [(ngModel)]="buildUrl">
            </mat-form-field>
            <button class="gather-data-button" mat-stroked-button (click)="fetchData()">Offerte laden</button>
        </div>
    </li>

    <!-- <li>
        <button class="hoverable-menu-item help-button" mat-button matTooltip="Help">
            <i class="material-icons">help</i>
        </button>
    </li> -->
    <li *ngIf="json">
        <button class="hoverable-menu-item" mat-button matTooltip="Save template" (click)="saveTemplate()">
            <i class="material-icons notranslate">save</i>
        </button>
    </li>
    <li *ngIf="json">
        <button class="hoverable-menu-item" mat-button [matMenuTriggerFor]="magicMenu" matTooltip="Create magic">
            <i class="material-icons notranslate">&#xe663;</i>
        </button>
    </li>
    <li *ngIf="savedTemplates && savedTemplates.length > 0">
        <button class="hoverable-menu-item" mat-button [matMenuTriggerFor]="savedTemplatesMenu" matTooltip="Saved templates">
            <i class="material-icons notranslate">undo</i>
        </button>
    </li>
    <li *ngIf="json">
        <button class="hoverable-menu-item" mat-button matTooltip="Import previous changes" (click)="importTemplate()">
            <i class="material-icons notranslate">system_update_alt</i>
        </button>
    </li>

    <!-- <li *ngIf="json != null">
        <button class="hoverable-menu-item" mat-button (click)="downloadData()" matTooltip="Download VTB Agent file">
            <i class="material-icons">get_app</i>
        </button>
    </li> -->
    <!-- <li>
        <label class="hoverable-menu-item file-loader-button" mat-button for="file-loader" matTooltip="Load VTB Agent File">
            <i class="material-icons">input</i>
        </label>
        <input type="file" id="file-loader" class="file-loader" (change)="gatherFromFile($event)" #fileImport>
    </li> -->
</ul>

<mat-menu #magicMenu="matMenu">
    <button mat-menu-item (click)="publishTemplate()" [disabled]="json == null">
        <i class="material-icons notranslate">open_in_new</i>PDF creëren
    </button>
    <button mat-menu-item [matMenuTriggerFor]="publishedTemplatesMenu" [disabled]="publishedTemplates == null || publishedTemplates.length < 1">
        <i class="material-icons notranslate">web</i>Klant PDF downloaden
    </button>
    <mat-menu #publishedTemplatesMenu="matMenu">
        <button mat-menu-item *ngFor="let template of publishedTemplates; let i = index" (click)="openPublishedTemplate(template)" [matMenuTriggerFor]="publishedTemplateOptionsMenu" [ngClass]="{ 'pending-background': template.status === 'pending'}">
            <i class="material-icons notranslate">open_in_new</i> {{ template.name || template.pdfName }} {{ template.type }} - {{ template.date | date:'dd-MM-yyyy HH:mm:ss' }}
            <span *ngIf="template.status === 'pending'">
                <!-- <button mat-icon-button class="pdf-update-button" (click)="recheckStatus(template)">
                    <i class="material-icons recheck-status">update</i>
                </button> -->
                <div class="template-loader" *ngIf="template.status === 'pending'"></div>
              </span>
            <mat-menu #publishedTemplateOptionsMenu="matMenu">
                <button mat-menu-item (click)="openPublishedTemplate(template)" [ngClass]="{ 'pending-background': template.status === 'pending'}">
                    <i class="material-icons notranslate">open_in_new</i> Open
                </button>
                <button mat-menu-item (click)="republishTemplate(template)" [ngClass]="{ 'pending-background': template.status === 'pending'}">
                    <i class="material-icons notranslate">refresh</i> Republish Template
                </button>
                <button mat-menu-item (click)="deleteTemplate(i)" [ngClass]="{ 'pending-background': template.status === 'pending'}">
                    <i class="material-icons notranslate">delete</i> Delete Template
                </button>
            </mat-menu>
        </button>
    </mat-menu>
</mat-menu>

<mat-menu #savedTemplatesMenu="matMenu">
    <button mat-menu-item *ngFor="let template of savedTemplates" (click)="openSavedTemplate(template)">
        <i class="material-icons notranslate">open_in_new</i> {{ template.name || template.pdfName }} {{ template.type }} - {{
        template.date | date:'dd-MM-yyyy HH:mm:ss' }}
    </button>
</mat-menu>
