import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  getAllTextElements(root) {
    const elements: any = root.querySelectorAll('div, div *');
    const results = [];
    let child;
    for (const element of elements) {
      child = element.childNodes[0];
      if (element.hasChildNodes() && child.nodeType === 3) {
        results.push(element);
      }
    }
    return results;
  }

  sortTemplatesByDate(templates) {
    return templates.sort((a, b) => {
      const dateA: any = new Date(a.date);
      const dateB: any = new Date(b.date);
      return dateB - dateA;
    });
  }

  reverseSortTemplatesByDate(templates) {
    return templates.sort((a, b) => {
      const dateA: any = new Date(a.date);
      const dateB: any = new Date(b.date);
      return dateA - dateB;
    });
  }
}
