<ng-container *ngIf="step === 'fill-url'">
    <p>It is possible to apply changes from a previous saved proposal into the current proposal,<br>please enter the URL below</p>
    <div>
        <mat-form-field>
            <input type="text" [(ngModel)]="buildUrl" matInput>
        </mat-form-field>
    </div>
    
    <button mat-flat-button color="primary" (click)="findTemplates()">Continue</button>
    <button mat-flat-button color="secondary" (click)="close()">Cancel</button>
</ng-container>

<ng-container *ngIf="step === 'choose-template'">

    <p><strong>Choose saved template</strong></p>
    <mat-selection-list>
        <mat-list-option *ngFor="let template of savedTemplates;" (click)="showMergeOptions(template)">{{template.name}} {{template.type}} {{template.date | date:'dd-MM-yyyy HH:mm:ss'}}</mat-list-option>
    </mat-selection-list>
    <button mat-flat-button color="secondary" (click)="close()">Cancel</button>
</ng-container>

<ng-container *ngIf="step === 'merge-template'">
    <p><strong>Choose fields to merge</strong></p>
    <br>
    <div class="template-loader" *ngIf="!changedFields.length"></div>
    <mat-selection-list *ngIf="changedFields.length" #fields>
        <ng-container *ngFor="let field of changedFields;">
            <ng-container *ngIf="field.fields">
                <br>
                <strong>{{field.name}}</strong>
                <div class="grouped" *ngFor="let f of field.fields;">
                    <span *ngIf="f.original" class="material-icons" (click)="openInfo(f.original, f.value)">info</span>
                    <mat-list-option  [value]="field" [disabled]="!field.exists">{{f.name}}</mat-list-option>
                </div>
            </ng-container>
            <ng-container *ngIf="!field.fields">
                <div class="grouped">
                    <span *ngIf="field.original" class="material-icons" (click)="openInfo(field.original, field.value)">info</span>
                    <mat-list-option [disabled]="!field.exists" [value]="field">{{field.name}}</mat-list-option>
                </div>
            </ng-container>
        </ng-container>
    </mat-selection-list>
    
    <button mat-flat-button color="primary" id="apply-merge" (click)="apply()">Apply</button>
    <button mat-flat-button color="secondary" (click)="close()">Cancel</button>
</ng-container>

<ng-container *ngIf="step === 'no-template'">
    <p>No saved templates found for this url.</p>
</ng-container>

<div class="text-diff" *ngIf="textDiff[0]">
    <div class="text-diff-inner">
        <div class="left" [innerHTML]="textDiff[0]"></div>
        <div class="right" [innerHTML]="textDiff[1]"></div>
        <div class="close-text-diff" (click)="closeTextDiff()">
            <span class="material-icons">
                close
            </span>
        </div>
    </div>
</div>