<mat-form-field *ngIf="imageUrl" class="image-url">
    <mat-label>Image url</mat-label>
    <input type="text" matInput placeholder="Image url" [value]="imageUrl" (input)="imageUrl = $event.target.value">
</mat-form-field>

<div class="buttons-container">
    <!-- <button class="change-button" mat-flat-button color="primary" (click)="addImage()">Change</button> -->
    <button mat-flat-button color="primary" class="media-save-button" (click)="saveChanges()">Save</button>
</div>

<ng-container *ngIf="originalUrl">
    <p><strong>Original</strong></p>
    <img [src]="originalUrl" width="100">

    <div class="buttons-container">
        <button *ngIf="originalUrl" class="change-button" mat-flat-button color="primary" (click)="revertImage()">Revert</button>
    </div>
</ng-container>