import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-static-image-edit',
  templateUrl: './static-image-edit.component.html',
  styleUrls: ['./static-image-edit.component.scss']
})
export class StaticImageEditComponent implements OnInit, OnChanges {
  @Input() content: any;
  @Input() type: any;
  @Input() isChanged = false;
  @Input() zraId: string;
  @Output() save = new EventEmitter();
  @Output() revert = new EventEmitter();
  imageUrl: string;
  originalUrl: string;

  constructor(public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.content) {
      const matches = this.content.match(/src="(.*?)"/);
      if (matches) {
        this.imageUrl = matches[1];
      }

      const original = this.content.match(/data-zra-original="(.*?)"/);
      if (original) {
        this.originalUrl = original[1];
      }
    }
  }

  ngOnChanges(): void {
    if (this.content) {
      const matches = this.content.match(/src="(.*?)"/);
      if (matches) {
        this.imageUrl = matches[1];
      }
      
      const original = this.content.match(/data-zra-original="(.*?)"/);
      if (original) {
        this.originalUrl = original[1];
      }
    }
  }

  // addImage() {
    // this.cdr.detectChanges();
    // this.content.setAttribute('src', this.imageUrl); Old way to update the image before save, but doesnt work anymore
  // }

  saveChanges() {
    this.save.emit({ zraId: this.zraId, imageUrl: this.imageUrl });
  }

  revertImage() {
    this.imageUrl = this.originalUrl;
    this.originalUrl = '';
    this.save.emit({ zraId: this.zraId, imageUrl: this.imageUrl });
  }
}
