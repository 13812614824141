import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { Auth0Module } from 'ngx-auth0';
import { JwtModule } from '@auth0/angular-jwt';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { PreviewComponent } from './components/preview/preview.component';
import { EditComponent } from './components/edit/edit.component';
import { TextEditorComponent } from './components/editors/text-editor/text-editor.component';
import { ImageEditorComponent } from './components/editors/image-editor/image-editor.component';
import { PlainPriceEditComponent } from './components/editors/plain-price-edit/plain-price-edit.component';
import { AdvancedPriceEditComponent } from './components/editors/advanced-price-edit/advanced-price-edit.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { StaticImageEditComponent } from './components/editors/static-image-edit/static-image-edit.component';

import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';


import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { SavedTemplatesDialogComponent } from './components/saved-templates-dialog/saved-templates-dialog.component';
import { PublishInfoDialogComponent } from './components/publish-info-dialog/publish-info-dialog.component';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';

export function tokenGetter() {
  return localStorage.getItem('id_token');
}

@NgModule({
  declarations: [
    AppComponent,
    PreviewComponent,
    EditComponent,
    TextEditorComponent,
    ImageEditorComponent,
    PlainPriceEditComponent,
    AdvancedPriceEditComponent,
    TopNavigationComponent,
    StaticImageEditComponent,
    SavedTemplatesDialogComponent,
    PublishInfoDialogComponent,
    ImportDialogComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatSliderModule,
    MatMenuModule,
    MatListModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link']
        ]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        authScheme: ''
      }
    }),
    Auth0Module.forRoot({
      WebAuthConfig: {
        domain: 'vanverre.eu.auth0.com',
        clientID: 'GUWpcjNuqtH0KevsgmjneOcDCRtZIV4S',
        scope: 'openid profile email',
        responseType: 'token',
        redirectUri: location.href
      },
      connection: 'Username-Password-Authentication'
    }),
  ],
  providers: [DataService, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
