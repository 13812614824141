import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EditObj } from 'src/app/models/editObj';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnChanges {
  @Input() editObj: EditObj;
  @Output() editor = new EventEmitter();
  isChanged = false;

  // buildUrl = 'https://jouwreisvoorstel.bohemianbirds.com/1405/91dd12b6-1ad9-4b85-9be4-9baf8ea02bd2/';
  // buildUrl = 'https://opreismet.vanverre.nl/228/4160d4b0-be34-4e96-a6d7-f9f280b861d9/';

  constructor(public dataService: DataService, private cd: ChangeDetectorRef) { }
  ngOnChanges() {

  }

  saveData(event, type) {
    this.editor.emit({ type: 'updatePreview', event });
  }

  revertData() {
    
  }

  // ngOnChanges() {
  //   if (!this.editObj) {
  //     return;
  //   }

  //   this.isChanged = false;
  //   for (const key in this.dataService.jsonChanges) {
  //     if (this.dataService.jsonChanges.hasOwnProperty(key)) {
  //       const field = this.getField();
  //       if (this.dataService.jsonChanges[key].hasOwnProperty(this.editObj.vtbObjectId) && this.dataService.jsonChanges[key][this.editObj.vtbObjectId][field]) {
  //         this.isChanged = true;
  //       }
  //     }
  //   }
  // }

  // saveData(event, type) {
  //   const field = (type === 'text') ? this.editObj.input.getAttribute('data-field') : 'media';
  //   if (this.editObj.type === 'static-image') {
  //     this.dataService.setStaticImageValue(event.key, event.url);
  //   } else {
  //     this.dataService.setValue(this.editObj.vtbObjectId, field, event);
  //   }


  //   if (type === 'text') {
  //     this.editObj.input.innerHTML = event;
  //   } else if (type === 'media') {
  //     this.handleImages(this.editObj, event);
  //   } else if (type === 'price') {
  //     this.editObj.element.innerHTML = event;
  //   }

  //   this.editObj = null;

  //   this.editor.emit({ type: 'updateJson' });
  //   this.cd.detectChanges();
  // }

  // revertData() {
  //   const field = this.getField();
  //   const vtbObjectId = this.editObj.vtbObjectId;

  //   const value = this.dataService.getOriginalValue(vtbObjectId, field);
  //   this.dataService.setValue(vtbObjectId, field, value);
  //   this.editor.emit({ type: 'updateJson' });
  //   this.cd.detectChanges();
  // }


  // getField() {
  //   return (this.editObj.input.getAttribute('data-field')) ? this.editObj.input.getAttribute('data-field') : 'media';
  // }
}
