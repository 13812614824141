<h3 *ngIf="max === 1" class="image-editor-description">Drag and Drop the images to change the order of the images</h3>
<button mat-flat-button color="primary" *ngIf="showUrl === false" class="media-upload-button" (click)="showUrl = true; cdr.detectChanges()">Add Image by Url</button>
<div *ngIf="showUrl === true" class="add-image-container">
  <mat-form-field>
    <mat-label>Image url</mat-label>
    <input type="text" matInput [(ngModel)]="imageUrl">
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="addImage()">Add Image by Url</button>
</div>
<div class="example-container" cdkDropListGroup>
  <div cdkDropList [cdkDropListEnterPredicate]="cdkCheck" (cdkDropListDropped)="dropListDropped()"></div>
  <div cdkDropList *ngFor="let image of images; let j = index" [cdkDropListEnterPredicate]="cdkCheck" (cdkDropListDropped)="dropListDropped()">
    <div cdkDrag class="example-box" (cdkDragMoved)="dragMoved($event);">
      <img class="image-editor-image" [src]="image">
      <span class="remove-media" (click)="removeImage(j)">
        <span class="material-icons">close</span>
      </span>
    </div>
  </div>
</div>
<button mat-flat-button color="primary" class="revert-button" *ngIf="isChanged" (click)="revertChanges()">Revert</button>
<button mat-flat-button color="primary" class="media-save-button" (click)="saveChanges()">Save</button>