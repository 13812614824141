import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EditObj } from './models/editObj';
import { NotificationsService } from 'angular2-notifications';
import { PreviewComponent } from './components/preview/preview.component';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('preview') preview: PreviewComponent;

  title = 'agent-tool';
  editables: object;
  editObj: EditObj;
  showEdit = false;

  busy = false;
  isDirty = false;

  constructor(
    public dataService: DataService,
    private auth: AuthService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.authenticate();

    window.addEventListener('beforeunload', (e) => {
      if(this.isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  }

  authenticate(err = null) {
    if (!this.auth.authenticated()) {
      this.auth.login(err).then(x => {
      }).catch(error => {
        if (error !== 'Canceled login to change password') {
          this.authenticate(err);
        }
      });
    } else {
      let parsed = JSON.parse(localStorage.getItem('profile'));
      if (localStorage.getItem('profile') && parsed['https://opreismet.vanverre.nl/userAuthorization'].appMetadata && parsed['https://opreismet.vanverre.nl/userAuthorization'].appMetadata.vtbAgent) { //TODO: URL shouldn't be hardcoded?
        environment.token_data = parsed['https://opreismet.vanverre.nl/userAuthorization'].appMetadata.vtbAgent;
      }
    }
  }

  handleTrigger(event) {
    this.editObj = event;
    this.showEdit = true;
    this.cdr.detectChanges();
  }

  handleMenu() {
    this.showEdit = !this.showEdit;
    this.cdr.detectChanges();
  }

  editorAction(event) {
    if(event.type === 'updatePreview') {
      this.isDirty = true;
    } else if(event.type === 'isSaved') {
      this.isDirty = false;
      return;
    }

    this.showEdit = false;

    if (this[`${event.type}Editor`]) {
      this[`${event.type}Editor`](event);
    }

    this.cdr.detectChanges();
  }

  updatePreviewEditor(data) {
    this.preview.updatePreview(data.event)
  }

  fetchEditor(event) {
    const notificationSettings = {
      timeOut: 8000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    };
    this.notificationsService.info('Started gathering data', '', notificationSettings);

    this.busy = true;
    this.preview.setUrl(event.url);
    this.http.get(`${event.url}`).toPromise().then((x: any) => {
      if (x.data) {
        this.editables = this.dataService.createEditablesList(x.data);
      }
    });
  }

  openSavedEditor(event) {
    this.preview.setData(event.data);
  }

  closeMenu() {
    this.showEdit = false;
  }

  busyTrigger(event) {
    this.busy = event;
    this.cdr.detectChanges();
  }
}
