import { Injectable } from '@angular/core';
import { Auth0Service } from 'ngx-auth0';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  private agentToolData: any = false;

  constructor(private auth0Service: Auth0Service, private jwtHelper: JwtHelperService) { }

  public authenticated() {
    const rawToken = localStorage.getItem('id_token');
    return !this.jwtHelper.isTokenExpired(rawToken);
  }

  public getToken() {
    return localStorage.getItem('id_token');
  }

  public bucketPrefix() {
    if (!this.agentToolData) {
      this.setAgentToolData();
    }

    return this.agentToolData.env.bucketPrefix;
  }

  public niceUrl() {
    if (!this.agentToolData) {
      this.setAgentToolData();
    }

    return this.agentToolData.env.niceUrl;
  }

  private setAgentToolData() {
    const token = localStorage.getItem('id_token');
    const decoded = this.jwtHelper.decodeToken(token);
    
    this.agentToolData = decoded['https://opreismet.vanverre.nl/user_authorization'].app_metadata.agentTool; //TODO: URL shouldn't be hardcoded?
  }

  public login(err = null) {
    const that = this;
    return new Promise((resolve, reject) => {
      const observable = that.auth0Service.loginByDialog(err);
      observable.subscribe(next => {
        localStorage.setItem('profile', JSON.stringify(next.idTokenPayload));
        localStorage.setItem('id_token', next.idToken);
        // that.store.dispatch(that.authActions.loginSuccess(next.profile));
        location.reload();
        resolve(next.idTokenPayload);
      }, error => {
        reject(error);
      });
    });
  }
}
