<h4>Update the price below</h4>
<div class="mat-slider-container">
    <mat-slider thumbLabel [min]="minimalPrice" [max]="maximumPrice" step="1" [(ngModel)]="price" (change)="recalcMargin($event)"></mat-slider>
    <span>Price: 
        <mat-form-field class="number-input">
            <input matInput type="number" [(ngModel)]="price" min="minimalPrice" max="maximumPrice" (change)="recalcMargin($event)"/>
        </mat-form-field>
    </span>
</div>
<!-- <div class="mat-slider-container">
    <mat-slider thumbLabel [min]="maxDec * -1" [max]="maxInc" step=".1" [(ngModel)]="margin" (change)="recalcPrice($event)"></mat-slider>
    <span>Margin:
        <mat-form-field class="number-input">
            <input matInput type="number" [(ngModel)]="margin" [min]="maxDec * -1" [max]="maxInc" (change)="recalcPrice($event)"/>
        </mat-form-field>
    </span>
</div> -->

<p *ngIf="checkPriceInput() === false" class="error">Only use 1 , or . to specify the decimal</p>
<!-- <p class="calculated-price">Calculated price: {{ price }}</p> -->
<button mat-flat-button color="primary" class="revert-button" *ngIf="isChanged" (click)="revertChanges()">Revert</button>
<button mat-flat-button color="primary" class="text-save-button" (click)="saveChanges()">Save</button>