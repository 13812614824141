import { Component, OnInit, Inject} from '@angular/core';
import { DialogData } from 'src/app/models/dialogData';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-saved-templates-dialog',
  templateUrl: './saved-templates-dialog.component.html',
  styleUrls: ['./saved-templates-dialog.component.scss']
})
export class SavedTemplatesDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SavedTemplatesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  openTemplate(template) {
    this.dialogRef.close(template);
  }

  openNew()
  {
    this.dialogRef.close(false);
  }

}
