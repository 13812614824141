// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  segments: {
    ALL: ['title', 'content'],
  },
  elements: {
    ALL: ['title', 'subTitle', 'additionalText']
  },
  TSOrder: [
    'internal',
    'general',
    'proposal',
    'invoice'
  ],
  extraFields: ['Bestemmingen', 'CoverThema', 'aanhef_output', 'Users', 'Reis_in_vogevlucht', 'Mw_info', 'Touroperator', 'prijs_details', 'Vlucht_optie', 'Ticketdeadline', 'ExtraInfoVlucht', 'Toon_Domestic', 'StoelReservering', 'ReisbescheidenPost', 'Vluchten geboekt', 'InfoStoelRes', 'Reisagent', 'Naam_Reisagent'],
  token_data: null
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
