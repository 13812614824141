import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const config = environment;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  miniWebsite: string;
  style: string;
  json: any = false;
  originalJSON: any;
  jsonChanges: object;
  iframe:any;
  baseUrl = 'https://ts-vtb-pdf-viewer-dev.firebaseapp.com/';
  // baseUrl = 'http://localhost:4201';

  public zraImages: object;
  public zraBlocks: object;
  public zraPrices: string[];

  constructor(private http: HttpClient) {
    this.jsonChanges = {
      segments: {},
      elements: {},
      extraFields: {},
      TSOrder: {},
      staticImages: {}
    };
  }

  setJSON(json) {
    this.json = json;
  }

  getJSON() {
    return this.json;
  }

  setIframe(iframe) {
    this.iframe = iframe;
  }

  getIframe() {
    return this.iframe;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  // getS3Data(url) {
  //   return new Promise((resolve) => {
  //     const decodedUri = encodeURIComponent(url);
  //     this.http.get(`https://rotr5dv6sc.execute-api.eu-west-1.amazonaws.com/dev/vtbagent?buildPath=${decodedUri}`,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json; charset=utf-8',
  //           'x-api-key': '4YI80BAS6H2N6INzwrmaS9FL7yqSA7e49apvKEcO'
  //         }
  //       }
  //     ).subscribe((result: any) => {
  //       if (result.html) {
  //         this.miniWebsite = result.html.replace(/sn-viewport--out/g, 'is-shown sn-viewport--in');
  //       }

  //       if (result.style) {
  //         this.style = result.style;
  //       }

  //       if (result.json) {
  //         this.json = eval('(' + result.json + ')'); // ugly fix, result.json is a stringified object and not JSON :(
  //         this.originalJSON = JSON.parse(JSON.stringify(this.json));
  //       }

  //       resolve();
  //     });
  //   });
  // }

  createEditablesList(itinerary) {
    const inputResults = [];
    const mediaResults = [];
    itinerary.segments.forEach(segment => {
      if (!config.token_data.segments.hasOwnProperty(segment.typeId) && !config.token_data.segments.hasOwnProperty('ALL')) {
        return;
      }

      const segmentFields = (config.token_data.segments.hasOwnProperty(segment.typeId)) ? config.token_data.segments[segment.typeId] : config.token_data.segments['ALL'];

      inputResults.push({
        vtbObjectId: segment.vtbObjectId,
        fields: (segmentFields.props) ? segmentFields.props : segmentFields
      });

      segment.elements.forEach(element => {
        let elementFields = (config.token_data.elements.hasOwnProperty(element.unitId)) ? config.token_data.elements[element.unitId] : config.token_data.elements['ALL'];
        if (config.token_data.segments.hasOwnProperty(segment.typeId) && config.token_data.segments[segment.typeId].elements && config.token_data.segments[segment.typeId].elements.hasOwnProperty(element.unitId)) {
          elementFields = config.token_data.segments[segment.typeId].elements[element.unitId];
        }

        inputResults.push({
          vtbObjectId: element.vtbObjectId,
          fields: elementFields
        });

        mediaResults.push(element.vtbObjectId);
      });
    });

    if (itinerary.TSOrder && config.token_data.TSOrder) {
      inputResults.push({
        vtbObjectId: itinerary.TSOrder.texts.vtbObjectId,
        fields: config.token_data.TSOrder
      });
    }

    if (config.token_data.extraFields) {
      itinerary.extraFieldValues.forEach(extraFieldValue => {
        if (extraFieldValue != null && extraFieldValue.fields != null) {
          extraFieldValue.fields.forEach(field => {
            if (config.token_data.extraFields.indexOf(field)) {
              inputResults.push({
                vtbObjectId: field.vtbObjectId,
                fields: ['value']
              });
            }
          });
        }
      });
    }

    return { inputs: inputResults, media: mediaResults };
  }

  setStaticImageValue(key, image) {
    if (!this.jsonChanges.hasOwnProperty('staticImages'))
      this.jsonChanges['staticImages'] = {};

    this.jsonChanges['staticImages'][key] = image;
  }

  setValue(vtbObjectId, key, value) {
    let hasFound = false;

    for (const segment of this.json.segments) {
      if (segment.vtbObjectId === vtbObjectId) {
        this.setChangesJSON('segments', vtbObjectId, key, value);
        segment[key] = value;
        hasFound = true;
        break;
      }
      for (const element of segment.elements) {
        if (element.vtbObjectId === vtbObjectId) {
          this.setChangesJSON('elements', vtbObjectId, key, value);
          element[key] = value;
          hasFound = true;
          break;
        }
      }
    }

    if (!hasFound && this.json.TSOrder && this.json.TSOrder.texts && this.json.TSOrder.texts.vtbObjectId === vtbObjectId) {
      this.setChangesJSON('TSOrder', vtbObjectId, key, value);
      this.json.TSOrder.texts[key] = value;
      hasFound = true;
    }

    if (!hasFound) {
      this.json.extraFieldValues.forEach(extraFieldValue => {
        if (extraFieldValue != null && extraFieldValue.fields != null) {
          extraFieldValue.fields.forEach(field => {
            if (field.vtbObjectId === vtbObjectId) {
              this.setChangesJSON('extraFields', vtbObjectId, key, value);
              field.value = value;
              hasFound = true;
            }
          });
        }
      });
    }
  }
  setZRAFields(data)
  {
    this.zraBlocks = data.zraBlocks;
    this.zraImages = data.zraImages;
    this.zraPrices = data.zraPrices;
  }

  setChangesJSON(type, vtbObjectId, key, value) {
    if (!this.jsonChanges[type][vtbObjectId]) {
      this.jsonChanges[type][vtbObjectId] = {};
    }

    this.jsonChanges[type][vtbObjectId][key] = value;
  }

  processData() {

  }

  getOriginalValue(vtbObjectId, key) {
    let returnValue = '';

    for (const segment of this.originalJSON.segments) {
      if (segment.vtbObjectId === vtbObjectId) {
        return segment[key];
      }
      for (const element of segment.elements) {
        if (element.vtbObjectId === vtbObjectId) {
          return element[key];
        }
      }
    }

    if (this.originalJSON.TSOrder && this.originalJSON.TSOrder.texts && this.originalJSON.TSOrder.texts.vtbObjectId === vtbObjectId) {
      return this.originalJSON.TSOrder.texts[key];
    }

    this.originalJSON.extraFieldValues.forEach(extraFieldValue => {
      extraFieldValue.fields.forEach(field => {
        if (field.vtbObjectId === vtbObjectId) {
          returnValue = field.value;
        }
      });
    });

    return returnValue;
  }

  downloadFile(url, buildUrl) {
    return this.http.get(url, { responseType: 'arraybuffer' });
  }
}
