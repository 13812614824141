<app-top-navigation [json]="dataService.json" (editor)="editorAction($event)" (busyTrigger)="busyTrigger($event)"></app-top-navigation>
<div class="container">
  <!-- background needs to work again! -->
  <!-- <div *ngIf="false&&dataService.json == null" class="start-background-container"></div> -->
  <app-preview class="expand-transition" 
    [miniWebsite]="dataService.miniWebsite" 
    [style]="dataService.style" 
    [ngClass]="{ 'expand-preview': showEdit === false, 'smaller-preview': showEdit === true }" 
    [json]="dataService.json" 
    [editables]="editables" 
    [showEdit]="showEdit"
    [busy]="busy"
    (trigger)="handleTrigger($event)" 
    (menuChange)="handleMenu()"
    (busyTrigger)="busyTrigger($event)" #preview></app-preview>

  <app-edit class="expand-transition" 
    [ngClass]="{ 'hide-menu': showEdit === false, 'show-menu': showEdit === true }" 
    [editObj]="editObj" 
    (editor)="editorAction($event)"></app-edit>
    
  <simple-notifications></simple-notifications>
</div>