import { Component, OnInit, Inject } from '@angular/core';
import { DialogData } from 'src/app/models/dialogData';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-publish-info-dialog',
  templateUrl: './publish-info-dialog.component.html',
  styleUrls: ['./publish-info-dialog.component.scss']
})
export class PublishInfoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PublishInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  close()
  {
    this.dialogRef.close(false);
  }

}
