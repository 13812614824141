import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { DialogData } from 'src/app/models/dialogData';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { MatSelectionList } from '@angular/material/list';
declare var require: any;
const Diff = require('diff');

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {
  @ViewChild(MatSelectionList) fields: MatSelectionList;
  @Input() itinerary;
  savedTemplates = [];
  changedFields = [];
  textDiff = ['', ''];
  buildUrl:string;
  step = 'fill-url';

  constructor(public dialogRef: MatDialogRef<ImportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private api: ApiService, public dataService:DataService) { }

  ngOnInit(): void {
  }

  findTemplates() {
    this.api.getSavedTemplates(this.buildUrl).then((result:any) => {
      if(result.templates && result.templates.length > 1) {
        this.showTemplates(result.templates)
      } else if(result.templates.length == 1) {
        this.showMergeOptions(result.templates[0]);
      } else {
        this.showNoOptions();
      }
    });
  }

  showTemplates(templates)
  {
    this.step = 'choose-template';
    this.savedTemplates = templates.map((template, i) => {
      const resultDate = new Date(Number(template.time));

      return {
        name: `Template ${i + 1}`,
        type: 'pdf changes',
        date: resultDate
      };
    }).reverse();
  }

  showMergeOptions(template)
  {
    this.step = 'merge-template';

    this.api.getTemplate(this.buildUrl, template).then(itinerary => {
      this.parseChangedFields(itinerary);
    })
  }

  openInfo(original, changed)
  {
    const diff = Diff.diffWords(original.replace(/<.*?>/g, ''), changed.replace(/<.*?>/g, ''));

    let span = null;
    let fragment = document.createElement('div');

    diff.forEach((part) => {
      const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
      
      span = document.createElement('span');
      span.style.color = color;
      span.appendChild(document.createTextNode(part.value));

      fragment.appendChild(span);
    });

    this.textDiff[0] = fragment.innerHTML.replace(/<span style="color: green;">(.*?)<\/span>/g, '').replace(/<span style="color: red;">(.*?)<\/span>/g, '<del>$1</del>');
    this.textDiff[1] = fragment.innerHTML.replace(/<span style="color: red;">(.*?)<\/span>/g, '').replace(/<span style="color: green;">(.*?)<\/span>/g, '<ins>$1</ins>');
  }

  parseChangedFields(itinerary)
  {
    let result = [];
    if(itinerary.overwrites) {
      Object.keys(itinerary.overwrites).forEach((key) => {
        result.push({
          name: key,
          type: 'overwrites',
          value: itinerary.overwrites[key]
        });
      });
    }

    itinerary.segments.forEach(segment => {
      if(segment.changedFields && segment.changedFields.length) {
        let fields = [];
        segment.changedFields.forEach(field => {
          fields.push({
            name: field,
            value: segment[field]
          });
        });
        result.push({
          name: segment.title,
          type: 'segment',
          vtbObjectId: segment.vtbObjectId,
          fields: fields,
        })
      }

      segment.elements.forEach(element => {
        if(element.changedFields && element.changedFields.length) {
          let fields = [];
          element.changedFields.forEach(field => {
            fields.push({
              name: field,
              value: element[field]
            });
          });
          result.push({
            name: element.title,
            type: 'product',
            productId: (element.TSProduct && element.TSProduct.id) ? element.TSProduct.id : null,
            fields: fields,
          })
        }
      });
    });

    if (itinerary.TSOrder && itinerary.TSOrder.texts) {
      if(itinerary.TSOrder.texts.changedFields && itinerary.TSOrder.texts.changedFields.length) {
        let fields = [];
        itinerary.TSOrder.texts.changedFields.forEach(field => {
          fields.push({
            name: field,
            value: itinerary.TSOrder.texts[field]
          });
        });
        result.push({
          name: "Order info",
          type: 'tsOrder',
          vtbObjectId: this.data.itinerary.TSOrder.texts.vtbObjectId,
          fields: fields,
        })
      }
    }

    // itinerary.extraFieldValues.forEach(extraFieldValue => {
    //   extraFieldValue.fields.forEach(field => {
    //     if(field.changedField) {
    //       result.push({
    //         name: field.translatedName,
    //         type: 'extraField',
    //         fieldId: field.id,
    //         value: field.value
    //       });
    //     }
    //   });
    // });

    this.changedFields = this.addAvailability(result);
  }

  addAvailability(result)
  {
    result.forEach(item => {
      if(item.type == 'overwrites') {
        item = this.searchOverwrite(item);
      } else if(item.type == 'segment') {
        item.exists = false;
      } else if(item.type == 'product') {
        item = this.searchProduct(item);
      } else if(item.type == 'tsOrder') {
        item.exists = true;
        item.fields.forEach(field => {
          field.original = this.data.itinerary.TSOrder.texts[field.name];
        });
      } else if(item.type == 'extraField') {
        item.exists = this.searchExtraField(item);
      }
    });

    return result;
  }

  searchOverwrite(item)
  {
    item.exists = false;

    if(this.dataService.zraBlocks.hasOwnProperty(item.name)) {
      item.exists = true;
      item.zraType = 'zraBlock';
      item.original = this.dataService.zraBlocks[item.name];
    }

    if(this.dataService.zraImages.hasOwnProperty(item.name)) {
      item.exists = true;
      item.zraType = 'zraImage';
      // item.original = this.dataService.zraImages[item.name];
    }
    
    if(this.dataService.zraPrices.indexOf(item.name) > -1) {
      item.exists = true;
      item.zraType = 'zraPrice';
      item.original = this.dataService.zraPrices[item.name];
    }

    return item;
  }

  searchProduct(item)
  {
    item.exists = false;

    this.data.itinerary.segments.forEach(segment => {
      segment.elements.forEach(element => {
        if(element.TSProduct && element.TSProduct.id && element.TSProduct.id == item.productId) {
          item.exists = true;
          item.vtbObjectId = element.vtbObjectId;
          item.fields.forEach(field => {
            if(field.name !== 'media')
              field.original = element[field.name];
          })
        }
      })
    });

    return item;
  }

  searchExtraField(item)
  {
    item.exists = false;

    this.data.itinerary.extraFieldValues.forEach(extraFieldValue => {
      extraFieldValue.fields.forEach(field => {
        if(field.id && field.id == item.fieldId) {
          item.exists = true;
          item.original = item.value;
        }
      })
    });

    return item;
  }

  showNoOptions()
  {
    this.step = 'no-template';  
  }

  apply()
  {
    const iframe = this.dataService.getIframe();

    this.fields.selectedOptions.selected.forEach(option => {
      const change = option.value;
      let data:any = false;

      if(change.type == 'overwrites') {
        if(change.zraType == 'zraBlock') {
          data = {
            zraId: change.name,
            content: change.value
          };
        } else if(change.zraType == 'zraImage') {
          let match = change.value.match(/src="(.*?)"/);
          if(match) {
            data = {
              zraId: change.name,
              imageUrl: match[1]
            };
          }
        } else if(change.zraType == 'zraPrice') {
          data = {
            zraId: change.name,
            price: change.value.replace(/[^0-9,.-]/g, '').replace(',-', '')
          };
        }
      } else if(change.type == 'tsOrder' || change.type == 'product') {
        option._text.nativeElement.textContent
        change.fields.forEach(field => {
          if(field.name === option._text.nativeElement.textContent) {
            if(field.name == 'media') {
              data = {
                vtbObjectId: change.vtbObjectId,
                vtbField: field.name,
                images: field.value.map(item => {
                 return item.url;
                })
              };
            } else {
              data = {
                vtbObjectId: change.vtbObjectId,
                vtbField: field.name,
                content: field.value
              };
            }
          }
        });
      }

      if(data !== false) {
        iframe.contentWindow.postMessage({
          type: 'fieldChanged',
          data: data
        }, this.dataService.getBaseUrl());
      }
    });

    this.dialogRef.close(false);
  }

  close()
  {
    this.dialogRef.close(false);
  }

  closeTextDiff() {
    this.textDiff = ['', ''];
  }

}
