import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare let window: any;

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, OnChanges {
  @ViewChild('editor') quillEditor: any;
  @Input() id: string;
  @Input() zraId: string;
  @Input() field: string;
  @Input() content: any;
  @Input() history: any;
  @Input() isChanged = false;
  @Output() save = new EventEmitter();
  @Output() revert = new EventEmitter();

  data: string;
  element: HTMLObjectElement;
  form: FormGroup;
  hasHistory = false;

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public cdr: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      html: new FormControl(''),
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.zraId != null) {
        this.form.get('html').patchValue(this.parseTemplateData(this.content));
      } else {
        this.form.get('html').patchValue(this.parseTemplateData(this.content));
      }
      this.quillEditor.quillEditor.history.clear();
    }, 200);
  }

  ngOnChanges(event) {
    if ((window.loadedSavedTemplate || ((event.id && event.id.currentValue !== event.id.previousValue) || (event.zraId && event.zraId.currentValue !== event.zraId.previousValue))) && event.content) {
      window.loadedSavedTemplate = false;
      setTimeout(() => {
        if (this.zraId != null) {
          this.form.get('html').patchValue(this.parseTemplateData(this.content));
        } else {
          this.form.get('html').patchValue(this.parseTemplateData(this.content));
        }
        this.quillEditor.quillEditor.history.clear();
      }, 200);
    }

    if(this.history && this.content && this.parseTemplateData(this.history).replace(/<.*?>/g, '') === this.parseTemplateData(this.content).replace(/<.*?>/g, '')) {
      this.hasHistory = false;
      this.cdr.detectChanges();
    } else if(this.history) {
      this.hasHistory = true;
      this.cdr.detectChanges();
    }
  }

  contentChanged(event) {
    this.data = event.html;
  }

  saveChanges() {
    let event:any = {
      content: this.data
    };

    if(this.zraId) {
      event.zraId = this.zraId;
    } else {
      event.vtbObjectId = this.id;
      event.vtbField = this.field;
    }

    this.save.emit(event);
  }

  revertChanges() {
    this.content = this.history;
    this.form.get('html').patchValue(this.parseTemplateData(this.content));
    this.hasHistory = false;
  }

  parseTemplateData(input) {
    input = input.replace(/\<\!\-\-\-\-\>/g, '');
    input = input.replace(/\<\/vtb\-segment.*?\>/g, '');
    input = input.replace(/\<vtb\-segment.*?\>/g, '');
    input = input.replace(/\<li\>/g, '<ul><li>');
    input = input.replace(/\<\/li\>/g, '</li></ul>');
    input = input.replace(/\<span.*?\>/g, '');
    input = input.replace(/\<\/span\>/g, '');
    input = input.replace(/\<div.*?\>/g, '');
    input = input.replace(/\<\/div\>/g, '');
    return input;
  }
}
