import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
declare let window: any;
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements AfterViewInit, OnChanges {
  @ViewChild('wrapper') wrapper: ElementRef;
  iframe;
  isInitialized = false;

  @Input() miniWebsite = '';
  @Input() style = '';
  @Input() json: any;
  @Input() editables: any;
  @Input() showEdit: any;
  @Input() busy: any = false;
  @Output() busyTrigger = new EventEmitter();
  @Output() trigger = new EventEmitter();
  @Output() menuChange = new EventEmitter();

  url = '';

  constructor(public dataService: DataService, public helperService: HelperService) { }

  ngAfterViewInit() {
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('src', `${this.dataService.getBaseUrl()}?mode=agent`);
    this.wrapper.nativeElement.append(this.iframe);
    this.dataService.setIframe(this.iframe);
  }

  ngOnChanges(changes) {
    if (!this.iframe || this.isInitialized || !this.editables) {
      return;
    }
    if ((changes.json != null || changes.editables != null) && this.busy !== false) {
      this.busyTrigger.emit(false);
    }

    this.iframe.contentWindow.postMessage({
      type: 'zraEditables',
      editables: this.editables
    }, this.dataService.getBaseUrl());
  }


  setData(data) {
    this.iframe.contentWindow.postMessage({
      type: 'zraJsonFromSaved',
      data: data
    }, this.dataService.getBaseUrl());
  }

  setUrl(url) {
    this.url = url;

    this.iframe.contentWindow.postMessage({
      type: 'zraJson',
      zraJsonFile: this.url
    }, this.dataService.getBaseUrl());

    window.addEventListener('message', (e) => {
      if (e.data.type) {
        if (e.data.type === 'zraFields') {
          this.dataService.setZRAFields(e.data);
        } else if (e.data.type === 'updateJSON') {
          this.dataService.setJSON(e.data.itinerary);
        } if (e.data.type === 'media') {
          this.trigger.emit({ vtbObjectId: e.data.vtbObjectId, maxImg: e.data.maxImg, data: e.data.images, zraId: null, type: e.data.type });
        } else if (e.data.type === 'static-image') {
          this.trigger.emit({ vtbObjectId: null, zraId: e.data.key, type: e.data.type, input: e.data.html });
        } else if (e.data.type === 'text') {
          if (e.data.vtbObjectId) {
            this.trigger.emit({ vtbObjectId: e.data.vtbObjectId, vtbField: e.data.vtbField, zraId: null, type: e.data.type, input: e.data.html, history: e.data.history });
          } else {
            this.trigger.emit({ vtbObjectId: null, zraId: e.data.key, type: e.data.type, input: e.data.html, history: e.data.history });
          }
        } else if (e.data.type === 'plain-price') {
          this.trigger.emit({ zraId: e.data.key, type: e.data.type, input: e.data.price, originalPrice: e.data.originalPrice, maxInc: parseInt(e.data.maxInc), maxDec: parseInt(e.data.maxDec) });
        }
      }

    }, false);
  }

  events(root) {
    const textElements = this.helperService.getAllTextElements(root);
    for (const text of textElements) {
      if (text.innerHTML && text.innerHTML.match(/[$€¥]/g) && text.innerHTML.match(/[$€¥]/g).length > 0) {
        text.addEventListener('click', event => {
          this.trigger.emit({ type: 'plain-price', input: text.innerHTML, element: text });
        });
      }
    }
  }

  checkStaticImages(root) {
    if (this.dataService.jsonChanges.hasOwnProperty('staticImages')) {
      const keys = Object.keys(this.dataService.jsonChanges['staticImages']);
      keys.forEach(key => {
        root.querySelectorAll(`img[data-agent-image="${key}"]`).forEach(image => {
          image.setAttribute('src', this.dataService.jsonChanges['staticImages'][key]);
        });
      });
    }
  }

  updatePreview(data) {
    this.iframe.contentWindow.postMessage({
      type: 'fieldChanged',
      data: data
    }, this.dataService.getBaseUrl());


  }

  distribute()
  {
    console.log('refresh');
    this.iframe.contentWindow.postMessage({
      type: 'zraRefresh'
    }, this.dataService.getBaseUrl());
  }

}
